import React from 'react';
import './PrivacyPolicy.css'

const PrivacyPolicy = () => {
    return (
        <div className='policy-container'>
            <div className='margin-bottom'>Welcome to Aradhanaa, operated by Ventureboo LLP . We are committed to protecting your privacy.
                This Privacy Policy outlines how we collect, use, and safeguard your information when you use our app and website.</div>
            <div className='margin-bottom'><span className='bold-number'> 1. Information We Collect</span> </div>
            <div className='margin-bottom'><span className='bold-number'> 1.1 Personal Information</span></div>
            <div className='margin-bottom'>We may collect personal information that you provide to us directly, including but not limited to:</div>
            <ul>
                <li>Name</li>
                <li>Email address</li>
                <li>Phone number</li>
                <li>Payment information</li>
                <li>User-generated content (e.g., comments, messages)</li>
            </ul>
            <div className='margin-bottom'><span className='bold-number'> 1.2 Usage Data</span> </div>
            <div className='margin-bottom'>We collect information about your interaction with our app and website, such as:</div>
            <ul>
                <li>Device information (e.g., device type, operating system)</li>
                <li>IP address</li>
                <li>Browser type and version</li>
                <li>Pages visited and time spent</li>
                <li>App usage data</li>
            </ul>
            <div className='margin-bottom'><span className='bold-number'> 1.3  Cookies and Tracking Technologies</span></div>
            <div className='margin-bottom'>We use cookies and similar technologies to enhance your experience. You can manage cookie preferences through your browser settings.</div>
            <div className='margin-bottom'><span className='bold-number'> 2.How We Use Your Information </span> </div>
            <div className='margin-bottom'>We use the information we collect for the following purposes:</div>
            <ul>
                <li>To provide and maintain our services</li>
                <li>To process transactions and manage accounts</li>
                <li>To communicate with you and respond to inquiries</li>
                <li>To personalize your experience and deliver content relevant to your interests</li>
                <li>To improve our app and website functionality</li>
                <li>To comply with legal obligations and protect our rights</li>
            </ul>
            <div className='margin-bottom'><span className='bold-number'> 3.  Sharing Your Information</span> </div>
            <div className='margin-bottom'>We do not sell or rent your personal information to third parties. We may share your information with:</div>
            <ul>
                <li>Service providers who assist us in delivering our services</li>
                <li>Legal authorities if required by law or to protect our rights</li>
                <li>Other parties with your consent or as directed by you</li>
            </ul>
            <div className='margin-bottom'> <span className='bold-number'> 4.  Data Security </span></div>
            <div className='margin-bottom'>We implement industry-standard security measures to protect your information. However, no method of transmission over the Internet or electronic storage is 100% secure.</div>
            <div className='margin-bottom'><span className='bold-number'> 5.  Your Rights</span> </div>
            <div className='margin-bottom'>You have the right to:</div>
            <ul>
                <li>Access and update your personal information</li>
                <li>Request the deletion of your personal information</li>
                <li>Object to the processing of your personal information</li>
                <li>Withdraw consent at any time</li>
            </ul>
            <div className='margin-bottom'>To exercise these rights, please contact us at [Your Contact Email].</div>
            <div className='margin-bottom'><span className='bold-number'> 6.  Third-Party Links </span></div>
            <div className='margin-bottom'>Our app and website may contain links to third-party websites. We are not responsible for the privacy practices of these websites. We encourage you to review their privacy policies.</div>
            <div className='margin-bottom'><span className='bold-number'> 7.  Children's Privacy</span> </div>
            <div className='margin-bottom'>Our services are not intended for individuals under the age of 13. We do not knowingly collect personal information from children under 13. If we become aware of any such data, we will take steps to delete it.</div>
            <div className='margin-bottom'><span className='bold-number'> 8.  Changes to This Privacy Polic </span>y</div>
            <div className='margin-bottom'>We may update this Privacy Policy from time to time. Any changes will be posted on this page with an updated effective date.</div>
            <div className='margin-bottom'> <span className='bold-number'> 9.  Contact Us</span> </div>
            <div className='margin-bottom'> If you have any questions or concerns about this Privacy Policy, please contact us at:</div>
            <div className='margin-bottom'>[Your Company Name]
                [Your Address]
                Email: [Your Contact Email]
                Phone: [Your Contact Phone Number]</div>
            <ul>
                <li>Ventureboo LLP</li>
                <li>[Your Address]</li>
                <li>Email: [Your Contact Email]</li>
                <li>Phone: [Your Contact Phone Number]</li>
            </ul>
            <div className='margin-bottom'><span className='bold-number'> Important Considerations </span>  </div>
            <div className='margin-bottom'><span className='bold-number'> Customization:</span> Tailor the template to reflect the actual practices and data handling procedures of your app and website.
            </div>
            <div className='margin-bottom'>
                <span className='bold-number'>Legal Compliance:</span> Ensure the policy complies with relevant privacy laws, such as GDPR for EU users and CCPA for California residents.
            </div>
            <div className='margin-bottom'> <span className='bold-number'>Updates:</span> Regularly review and update the privacy policy to reflect changes in your data practices or legal requirements.</div>
            <div className='margin-bottom'><span className='bold-number'>Consultation:</span> It is advisable to consult with a legal professional to ensure the privacy policy is comprehensive and legally sound.</div>
            <div className='margin-bottom'> This privacy policy will help communicate your commitment to user privacy and build trust with your users by clearly outlining how their data is handled.</div></div>

    )
}

export default PrivacyPolicy
