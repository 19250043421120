import React from 'react'
import './Header.css';
import LOGO from '../../../assets/images/logo.svg';
import { useNavigate } from 'react-router-dom';

const Header = () => {
    const navigate = useNavigate()
    return (
        <div className='header-container'>
            <header className="header">
                <div onClick={() => { navigate('/') }}>
                    <img src={LOGO} className='logo-image'/>
                </div>
                <div className='header-button'>
                    <nav>
                        <ul>
                            <li><a href="Privacy">Privacy & Terms</a></li>
                        </ul>
                    </nav>
                    <a className='download-button' target='_blank' href='https://play.google.com/store/apps/details?id=com.aaradhana'>
                        Download App
                    </a>
                </div>
            </header> </div>
    )
}

export default Header
