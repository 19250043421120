import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Homepage from '../../components/organisms/homepage/homepage'
import PrivacyPolicy from '../../components/molecules/PrivacyPolicy/PrivacyPolicy'
import Header from '../../components/molecules/Header/Header'
import Footer from '../../components/molecules/Footer/Footer'

const AppRoutes = () => {
    return (
        <div>
            <Header />
            <Routes>
                <Route path='/' element={<Homepage />} />
                <Route path='/Privacy' element={<PrivacyPolicy />} />
            </Routes>
            <Footer />
        </div>
    )
}

export default AppRoutes
