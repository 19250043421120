import React from 'react';
import { image } from '../../../app/utils/common';
import './homepage.css'

const ContentBlock = ({ imgSrc, title, children, style }) => (
    <div className='homeContent-container'>
        {imgSrc && <img src={imgSrc} className='ss-image' />}
        <div className='homeContent' style={style}>
            <span>{title}</span>
            {children}
        </div>
    </div>
);

const Homepage = () => {
    return (
        <div className='homepage-container'>
            <div className='homeContent-container'>
                <img src={image.ss1} className='ss-image-cover' />
                <div className='homeContent'>
                    <span>Aradhanaa: </span>
                    Your Gateway to Spiritual Connection. Welcome to Aradhanaa , the ultimate app designed to bring you closer to your spiritual journey. With Aradhanaa, you can seamlessly integrate your faith into your daily life and stay connected with the vibrant Hindu community across India. Explore our features that cater to every aspect of your spiritual needs.
                </div>
            </div>
            <ContentBlock title="**View Temple Events : ">
                Stay updated with the latest events at your favorite temples. Whether it's a special festival, a religious gathering, or a daily ritual, Aradhanaa provides you with detailed information and reminders, so you never miss an important occasion.
            </ContentBlock>

            <div className='homepage-flex'>
                <ContentBlock imgSrc={image.ss3} title="Real-time Updates: ">
                    Get instant notifications about upcoming events.
                </ContentBlock>
                <ContentBlock imgSrc={image.ss3} title="Event Details: ">
                    Access comprehensive information about each event, including schedules, locations, and participating deities.
                </ContentBlock>
                <ContentBlock imgSrc={image.ss4} title="Personalized Alerts: ">
                    Set your preferences to receive alerts for events that matter to you.
                </ContentBlock>
            </div>

            <ContentBlock title="**Consume Media from Temples : ">
                Immerse yourself in the divine with our extensive media library. Watch live streams of temple rituals, listen to spiritual discourses, and view photo galleries of temple events, all from the comfort of your home.
            </ContentBlock>

            <div className='homepage-flex'>
                <ContentBlock imgSrc={image.ss3} title="Video Library: ">
                    Explore recorded videos of past events and spiritual talks.
                </ContentBlock>
                <ContentBlock imgSrc={image.ss4} title="Photo Galleries: ">
                    Browse through beautiful images capturing the essence of temple life.
                </ContentBlock>
            </div>

            <ContentBlock title="**Instagram-like Feed : ">
                Dive into a feed filled with engaging religious content. Scroll through daily inspiration, devotional quotes, and community posts, all tailored to your spiritual interests.
            </ContentBlock>

            <div className='homepage-flex'>
                <ContentBlock imgSrc={image.ss3} title="Daily Inspiration: ">
                    Receive motivational quotes and teachings from renowned spiritual leaders.
                </ContentBlock>
                <ContentBlock imgSrc={image.ss4} title="Community Interaction: ">
                    Connect with fellow devotees, share your thoughts, and engage in meaningful discussions.
                </ContentBlock>
                <ContentBlock imgSrc={image.ss4} title="Tailored Content: ">
                    Enjoy content that resonates with your spiritual path and interests.
                </ContentBlock>
            </div>

            <ContentBlock title="**Book Poojas with Temples : ">
                Simplify your spiritual rituals by booking poojas and religious ceremonies through the app. Choose from a variety of pooja options and make your offerings with ease.
            </ContentBlock>

            <div className='homepage-flex'>
                <ContentBlock imgSrc={image.ss3} title="Easy Booking: ">
                    Select your preferred pooja and schedule it at your convenience.
                </ContentBlock>
                <ContentBlock imgSrc={image.ss3} title="Payment Options: ">
                    Make secure payments through the app for hassle-free transactions.
                </ContentBlock>
                <ContentBlock imgSrc={image.ss4} title="Confirmation Alerts: ">
                    Receive instant confirmation and reminders for your booked poojas.
                </ContentBlock>
            </div>

            <ContentBlock title="**Show Stats and Numbers for the Religious Industry : ">
                Gain insights into the religious landscape of India with our comprehensive statistics and data. Understand trends, participation rates, and the impact of spirituality in modern society.
            </ContentBlock>

            <div className='homepage-flex'>
                <ContentBlock imgSrc={image.ss3} title="Industry Insights: ">
                    Access data-driven reports and analyses on religious practices and trends.
                </ContentBlock>
                <ContentBlock imgSrc={image.ss3} title="Community Engagement: ">
                    Learn about the impact of spirituality on communities and individuals.
                </ContentBlock>
                <ContentBlock imgSrc={image.ss4} title="Cultural Significance: ">
                    Explore the role of religion in shaping cultural and social dynamics.
                </ContentBlock>
            </div>

            <ContentBlock title="**Join the Aradhanaa Community : " style={{ fontSize: "22px" }}>
                Aradhanaa is more than just an app; it's a community of like-minded individuals on a journey of spiritual growth.
                Whether you're seeking to deepen your faith or simply stay connected with the Hindu community,
                Aradhanaa offers the tools and resources you need to enrich your spiritual life.
            </ContentBlock>
            <div>
                <ContentBlock title="**Download Aradhanaa today** " style={{ fontSize: "22px" }}>
                    and embark on a transformative journey of devotion and connection.

                </ContentBlock>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: "12px" }}>
                    <a className='download-button' target='_blank' href='https://play.google.com/store/apps/details?id=com.aaradhana'>
                        Download Aradhanaa
                    </a>
                </div>
            </div>

        </div>
    );
};

export default Homepage;
