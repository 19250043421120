import React from 'react'

const Footer = () => {
  return (
    <div className='footer'>
       <p>&copy; 2024 Aradhanaa. All rights reserved.</p>
    </div>
  )
}

export default Footer
